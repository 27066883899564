import { WorkflowStates } from "../model/WorkflowStates";

export const buildPath = (basePath, subPath) => `/${basePath}/${subPath}`;

const ROUTES_BASE = {
  SOURCE_SELECTION: "source-selection",
  INCOMING_PAPERS: "incoming-papers",
  ELIGIBILITY_ASSESSMENT: "eligibility-assessment",
  PAPER_ANALYSIS: "paper-analysis",
  EXTRACTION: "extraction",
  RELEASE: "release",
  MISCELLANEOUS: "miscellaneous",
  PAPER_IMPORT: "paper-import",
  REFERENCE_LOOKUP: "reference-lookup",
  AUTO_PAPER_SELECTION_REVIEW: "auto-paper-selection-review",
  PAPER_SELECTION: "paper-selection",
  PAPER_SELECTION_REVIEW: "paper-selection-review",
  PAPER_UPLOAD: "paper-upload",
  NOT_FOUND_PAPERS: "not-found-papers",
  DESIGN_PRE_ANNOTATION: "design-pre-annotation",
  ELIGIBILITY_ASSESSMENT_MAPPING: "registry-mapping",
  MANUAL_ASSESSMENT: "manual-assessment",
  PRE_ANNOTATION: "pre-annotation",
  ANNOTATION: "annotation",
  REVIEW: "review",
  AUTO_EXTRACTION: "auto",
  EXTRACTION_VALIDATION_VARIABLES: "validation-variables",
  EXTRACTION_VALIDATION_DATA: "validation-data",
  AUTO_RELEASE_VALIDATION: "auto-release-validation",
  RELEASE_VALIDATION: "release-validation",
  RELEASE_VALIDATION_REVIEW: "release-validation-review",
  RELEASED: "released",
  TO_BE_DISCUSSED: "to-be-discussed",
  LONG_TERM_TO_BE_DISCUSSED: "long-term-to-be-discussed",
  ALL_PAPERS: "all-papers",
  ADMINISTRATION: "administration",
};

const NESTED_ROUTES = Object.keys(ROUTES_BASE).reduce((accumulator, key) => {
  accumulator[`NESTED_${key}`] = `${ROUTES_BASE[key]}/*`;
  return accumulator;
}, {});

export const ROUTES = {
  ...ROUTES_BASE,
  ...NESTED_ROUTES,
};

export const ROUTES_PROJECT_MANAGEMENT = {
  PROJECT_MANAGEMENT: "project-management",
  PROJECT_CREATION: "creation",
  PROJECT_STATUS: "status",
  RESPONSIBILITIES: "responsibilities/*",
  DATA_MANAGEMENT: "data-management/*",
  ICTRP_UPDATE: "ictrp-update",
  NESTED_PROJECT_MANAGEMENT: "project-management/*",
};

export const stateName2Route = Object.entries(WorkflowStates).reduce((accumulator, [key, value]) => {
  const stateName = value.stateName;
  let route;

  switch (stateName) {
    case WorkflowStates.IMPORTED.stateName:
      route = buildPath(ROUTES.SOURCE_SELECTION, ROUTES.PAPER_IMPORT);
      break;
    case WorkflowStates.AUTOMATED_REFERENCE_LOOKUP.stateName:
      route = buildPath(ROUTES.SOURCE_SELECTION, ROUTES.REFERENCE_LOOKUP);
      break;
    case WorkflowStates.AUTO_PAPER_SELECTION_REVIEW.stateName:
      route = buildPath(ROUTES.INCOMING_PAPERS, ROUTES.AUTO_PAPER_SELECTION_REVIEW);
      break;
    case WorkflowStates.SELECTION_ASSESSMENT.stateName:
      route = buildPath(ROUTES.INCOMING_PAPERS, ROUTES.PAPER_SELECTION);
      break;
    case WorkflowStates.SELECTION_ASSESSMENT_REVIEW.stateName:
      route = buildPath(ROUTES.INCOMING_PAPERS, ROUTES.PAPER_SELECTION_REVIEW);
      break;
    case WorkflowStates.INCOMING.stateName:
      route = buildPath(ROUTES.INCOMING_PAPERS, ROUTES.PAPER_UPLOAD);
      break;
    case WorkflowStates.INCOMING_NOT_FOUND.stateName:
      route = buildPath(ROUTES.INCOMING_PAPERS, ROUTES.NOT_FOUND_PAPERS);
      break;
    case WorkflowStates.ELIG_ASSESS_AUTO_PREANNOTATION_DESIGN.stateName: 
      route = buildPath(ROUTES.ELIGIBILITY_ASSESSMENT, ROUTES.DESIGN_PRE_ANNOTATION);
      break;
    case WorkflowStates.ELIGIBILITY_ASSESSMENT_MAPPING.stateName:
      route = buildPath(ROUTES.ELIGIBILITY_ASSESSMENT, ROUTES.ELIGIBILITY_ASSESSMENT_MAPPING)  
      break;
    case WorkflowStates.ELIGIBILITY_MANUAL_ASSESSMENT.stateName:
      route = buildPath(ROUTES.ELIGIBILITY_ASSESSMENT, ROUTES.MANUAL_ASSESSMENT);
      break;   
    case WorkflowStates.ANALYSIS_PREANNOTATION.stateName:
      route = buildPath(ROUTES.PAPER_ANALYSIS, ROUTES.PRE_ANNOTATION);
      break;
    case WorkflowStates.ANALYSIS.stateName:
      route = buildPath(ROUTES.PAPER_ANALYSIS, ROUTES.ANNOTATION) ;
      break;
    case WorkflowStates.ANALYSIS_REVIEW.stateName:
      route = buildPath(ROUTES.PAPER_ANALYSIS, ROUTES.REVIEW);
      break; 
    case WorkflowStates.AUTO_EXTRACTION.stateName:
      route = buildPath(ROUTES.EXTRACTION, ROUTES.AUTO_EXTRACTION);
      break;
    case WorkflowStates.EXTRACTION_VALIDATION_VARIABLES.stateName:
      route = buildPath(ROUTES.EXTRACTION, ROUTES.EXTRACTION_VALIDATION_VARIABLES);
      break;
    case WorkflowStates.EXTRACTION_VALIDATION_DATA.stateName:
      route = buildPath(ROUTES.EXTRACTION, ROUTES.EXTRACTION_VALIDATION_DATA);
      break;
    case WorkflowStates.TO_BE_DISCUSSED.stateName:
      route = buildPath(ROUTES.MISCELLANEOUS, ROUTES.TO_BE_DISCUSSED);
      break;
    case WorkflowStates.LONG_TERM_TO_BE_DISCUSSED.stateName:
      route = buildPath(ROUTES.MISCELLANEOUS, ROUTES.LONG_TERM_TO_BE_DISCUSSED);
      break;
    default:
      route = "";
  }

  accumulator[stateName] = route;
  return accumulator;
}, {});


export const state2Route = Object.entries(WorkflowStates).reduce((accumulator, [key, value]) => {
  const state = value.state;
  let route;

  switch (state) {
    case WorkflowStates.SELECTION_ASSESSMENT.state:
      route = buildPath(ROUTES.INCOMING_PAPERS, ROUTES.PAPER_SELECTION);
      break;
    case WorkflowStates.SELECTION_ASSESSMENT_REVIEW.state:
      route = buildPath(ROUTES.INCOMING_PAPERS, ROUTES.PAPER_SELECTION_REVIEW);
      break;
    case WorkflowStates.ANALYSIS.state:
      route = buildPath(ROUTES.PAPER_ANALYSIS, ROUTES.ANNOTATION);
      break;
    case WorkflowStates.ANALYSIS_REVIEW.state:
      route = buildPath(ROUTES.PAPER_ANALYSIS, ROUTES.REVIEW);
      break;
    default:
      route = "";
  }

  accumulator[state] = route;
  return accumulator;
}, {});
